<template>
  <div id="scan-index-page">
    <AdvanceFilter @search="searchFilter" @reset="filterData = null;getDataFromApi('refresh')" :loading="loading"
      @remove="searchFilter($event, 'remove')"/>
    <v-card>
      <v-row class="ma-0 pa-3 pb-5" align="center" justify="end">
        <v-col cols="7" class="d-flex d-xs-none d-sm-none pa-0">
        </v-col>
        <v-col cols="12" class="col-md-5 col-lg-5 col-xl-5 pa-3">
          <div class="w-full">
            <v-text-field v-model.trim="search" append-icon="mdi-magnify" class="pt-0"
              label="" placeholder="Search Scan" :disabled="loading" hide-details
              @input="searchDatatable">
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="tableItems"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-1"
      >
        <template v-slot:[`item.date`]="{item, header}">
          {{ $helpers.getMomentDatas(header.format, item[header.field]) }}
        </template>
        <template v-slot:[`item.action`]="{item}">
          <v-icon small class="mr-2 cursor-pointer" color="primary" @click="$router.push(`scans/${item.id}`)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';

export default {
  components: {
    AdvanceFilter: () => import("./components/AdvanceFilter"),
  },
  data() {
    return {
      totalItems: 0,
      tableItems: [],
      search: '',
      filterData: null,
      loading: true,
      options: {},
      headers: [
        {text: 'Serial', value: 'id'},
        {text: 'Type', value: 'type'},
        {text: "Date/Time", value: "date", field: 'timestamp', format: 'DD-MM-YYYY hh:mm A', sortable: false},
        {text: 'Registration', value: 'licensePlate'},
        {text: 'Car Color', value: 'carColor'},
        {text: "Client", value: "client_name"},
        {text: 'Agent', value: 'agent_name'},
        // { text: "Damage Count", value: "damage_count" },
        {text: "Status", value: "status"},
        {text: 'Action', value: 'action', sortable: false}
      ],
      pageLink: `${window.VUE_APP_MS_URL}scan/scans?page=1`,
      prevOptions: null
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.searchDatatable = _.debounce(this.searchDatatable, 500);
  },
  mounted() {
    this.wsScanData();
  },
  methods: {
    ...mapActions(['getModel', 'deleteScan']),

    searchDatatable() {
      this.getDataFromApi('search')
    },
    getDataFromApi(type) {
      this.loading = true;
      this.getDTDetails(type).then(data => {
        this.tableItems = data.items;
        this.totalItems = data.total;
        this.loading = false;
      }).catch(err => {
        this.tableItems = [];
        this.totalItems = 0;
        this.loading = false;
      });
    },
    getDTDetails(type) {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options,
            endPoint = new URL(this.pageLink);
        endPoint.searchParams.set("per_page", itemsPerPage);
        endPoint.searchParams.set("page", page);
        if (type) endPoint.searchParams.set("page", 1);
        if(this.filterData) {
          Object.keys(this.filterData).map(key => this.filterData[key] && endPoint.searchParams.set(key, this.filterData[key]));
        }
        if (this.search !== '') {
          endPoint.searchParams.set("q", this.search);
        }
        this.getAPIdata(endPoint, this.options, type).then(resp => {
          let {items, total} = resp;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }
          this.prevOptions = this.options;
          resolve({
            items,
            total,
          })

        }).catch(err => reject(err));
      });
    },
    getAPIdata(endPoint, options, type) {
      const {page, itemsPerPage} = options;
      return new Promise((resolve, reject) => {
        if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
          resolve({items: this.tableItems, total: this.totalItems});
          return true;
        }
        this.getModel(endPoint.toString()).then(resp => {
          resolve(resp);
        }).catch(err => reject(err));
      });
    },
    searchFilter(data, type) {
      if (type) {
        if (this.filterData && this.filterData[data]) this.filterData[data] = null;
        return;
      }
      this.filterData = data;
      this.getDataFromApi('filter')
    },
    deleteScanItem(scanId) {
      this.deleteScan(scanId).then(() => this.getDataFromApi('delete'));
    },
    wsBind(scan, event) {
      const itemIndex = this.tableItems.findIndex(item => item.id === scan.id);
      if (event && itemIndex != -1) {
        this.tableItems.splice(itemIndex, 1, scan);
      }
      if (!event) this.tableItems.splice(0, 0, scan);
    },
    wsScanData() {
      Echo.channel('scan').listen('.created', (scan) => this.wsBind(scan));      
      Echo.channel('scan').listen('.updated', (scan) => this.wsBind(scan, 'update'));
    }
  },
  beforeDestroy() {
      Echo.leaveChannel('scan');
  }
};
</script>
